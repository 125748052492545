import { Data } from "../entities.typings";

const clientProfitability = "Client profitability";
const changeManagement = "Change management";
const dataQuality = "Data quality";

export const cprofitData: Data[] = [
  {
    name: "Portfolio Analytics",
    icon: "business_center",
    section: clientProfitability,
    link: process.env.REACT_APP_PORTFOLIO_ANALYTICS_URL,
    description:
      "Client profitability dashboard dedicated to bankers, RMs as well as coverage analyst, which gives a full vision on clients of your portfolio.",
    visibility: "sgview",
  },
  {
    name: "Sales 360",
    icon: "attach_money",
    section: "Sales efficiency",
    link: process.env.REACT_APP_SALES_ANALYTICS_360_URL,
    description: "Your daily sales performance dashboard analysis and monitoring for sales people and sales managers.",
    visibility: "markview",
  },
  {
    name: "Correction module",
    icon: "border_color",
    section: dataQuality,
    link: process.env.REACT_APP_CORRECTION_URL,
    description:
      "The module used during correction campaigns to perform corrections on NBI, RWA and the client sharing.",
    visibility: "correction",
  },
  {
    name: "Forecast module",
    icon: "query_stats",
    section: dataQuality,
    link: process.env.REACT_APP_FORECAST_URL,
    description: "Module used during to post actuals and RWA forecasts.",
    visibility: "forecast",
  },
  {
    name: "PSM/CBC",
    icon: "insights",
    section: clientProfitability,
    link: process.env.REACT_APP_PSM_URL,
    description:
      "The Pre Screening Memo (PSM) is a business case for the decision process of the prioritization of CBA commitments.",
    visibility: "psm",
  },
  {
    name: "Data loading",
    icon: "file_upload",
    section: clientProfitability,
    link: process.env.REACT_APP_MASSUPLOAD_URL,
    description: "This is the section that data suppliers manually upload data to feed into C-Profit.",
    visibility: "massupload",
  },
  {
    name: "Data collect",
    icon: "upload_file",
    section: clientProfitability,
    link: process.env.REACT_APP_DATACOLLECT_URL,
    description: "New Module only for suppliers to integrate data into C-profit, manually or through the API.",
    visibility: "datacollect",
  },
  {
    name: "Client Contribution",
    icon: "query_stats",
    section: clientProfitability,
    link: process.env.REACT_APP_CLIENTCONTRIBUTION_URL,
    description: "This is the section used to Client contribution configuration, retrieve Profit Centers, SSBLs and Retention rate information.",
    visibility: "clientcontribution",
  },
  {
    name: "Data Quality module",
    icon: "playlist_add_check",
    section: dataQuality,
    link: process.env.REACT_APP_DATAQUALITY_URL,
    description: "Module used to manage automated functional checks and data consistency to optimize Data quality monitoring.",
    visibility: "dataquality",
  },
  {
    name: "Rosetta",
    icon: "stacked_bar_chart",
    section: "Data analysis",
    link: "https://digital.fr.world.socgen/cli-projects/onlineTutorials/page_rosetta.html",
    description:
      " Rosetta, the Excel add-in is a self-service query tool that allows you to extract data from C-Profit using APIs.",
    visibility: "all",
  },
  {
    name: "User statistics",
    icon: "manage_search",
    section: changeManagement,
    link: "https://digital.fr.world.socgen/cli-projects/cProfitMonitoring/index.html",
    description: "Here is the place where you can find the detailed user statistics of widgets.",
    visibility: "all",
  },
  {
    name: "Online tutorials",
    icon: "ondemand_video",
    section: changeManagement,
    link: "https://digital.fr.world.socgen/cli-projects/onlineTutorials/",
    description: "Have a look at our videos tutorials to get quickly started.",
    visibility: "all",
  },
  {
    name: "For developers",
    icon: "code",
    section: "Advanced users",
    link: "https://developer.sgmarkets.com/explore/api?tags=cprofit",
    description:
      "Section reserved for developpers and advanced users to discover how to use C-Profit data to create your own widgets/dashboards.",
    visibility: "all",
  },
];
