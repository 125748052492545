import * as React from "react";
import { Data } from "../entities.typings";
import { Icon } from "./Icon";

interface Props {
  data: Data;
}

export const Card: React.FunctionComponent<Props> = ({ data }: Props) => (
  <div className="col-12 col-xl-3 col-lg-4 col-sm-6 mb-4">
    <a href={`${data.link}`}>
      <div
        className="card card-bordered card-raising position-relative"
        style={{ paddingTop: "100%", cursor: "pointer" }}
      >
        <div className="position-absolute" style={{ top: 0, left: 0 }}>
          <div className="card-header">
            <div className="d-flex">
              <Icon className="text-primary" size="lg" iconName={data.icon} />
              <p className="ml-auto pt-1 text-secondary font-weight-medium text-large">{data.section}</p>
            </div>

            <h3 className="text-primary mt-0">{data.name}</h3>
            <p className="text-large text-primary pt-1">{data.description}</p>
          </div>
        </div>
      </div>
    </a>
  </div>
);
