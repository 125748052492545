import * as React from "react";
import { join, noop } from "lodash";
import { ComponentSize } from "./component.typings";

interface IconProps {
  className?: string;
  size?: ComponentSize;
  onClick?: (e?: any) => void;
}

interface MDIconProps extends IconProps {
  iconName: string;
}

export const generateIconClassName = (size?: ComponentSize, className?: string): string =>
  join(["icon", size ? `icon-${size}` : "", className || ""], " ").trim();

export const Icon: React.FunctionComponent<MDIconProps> = ({
  iconName,
  size,
  className,
  onClick = noop,
}: MDIconProps) => (
  <i className={generateIconClassName(size, className)} onClick={onClick}>
    {iconName}
  </i>
);
