import { get, isEmpty } from "lodash";
import { ToastMessage } from "./api.typings";

export const getErrorMessage = async (response: Response, toastMessage?: ToastMessage) => {
  const toast = get(toastMessage, "friendlyErrorMessage");
  if (toast) {
    return toast;
  }

  const responseObject = JSON.parse(await response.text());
  return !isEmpty(responseObject.detail)
    ? responseObject.detail
    : !isEmpty(response.statusText)
    ? response.statusText
    : responseObject.title;
};
