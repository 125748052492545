import { get } from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getAuthenticationHeader } from "../../../api/api";
import { UserApi } from "../../../api/users.api";
import { Card } from "../../common/Card";
import { UserProfile } from "../../entities.typings";
import { cprofitData } from "../../utils/CprofitData";

const initialState: UserProfile = {
  validatedRequestCProfit: { cerberusRole: "", claims: [] },
  userProfile: "",
};

export const Home: React.FC = () => {
  const [userState, setUserState] = React.useState<UserProfile | null>(initialState);

  const fetchUserClaims = () => {
    const email = getAuthenticationHeader().userEmail;
    if (email) {
      UserApi.getUser(email).then((userData) => {
        if (userData?.validatedRequestCProfit) {
          setUserState({
            validatedRequestCProfit: userData.validatedRequestCProfit,
            userProfile: userData.userProfile,
          });
        } else setUserState(null);
      });
    }
  };

  React.useEffect(() => {
    fetchUserClaims();
  }, []);
  

  const claims = get(userState?.validatedRequestCProfit, "claims");
  const filteredData = userState
    ? cprofitData.filter(({ visibility }) =>
        claims?.some((claim) => claim.domainId === visibility || visibility === "all")
      )
    : cprofitData.filter(({ visibility }) => visibility === "all");

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-5">
          <div className="row">
            <div className="col-12 py-3">
              <h1>
                <FormattedMessage id="home.welcome" />
              </h1>
            </div>
          </div>
          <div className="row">
            {filteredData.map((element) => (
              <Card key={element.name} data={element} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
