import React, { useEffect, useState } from 'react';
import { useUserInfo } from './useUserInfo';

type OriginNetwork = 'LAN' | 'WAN' | 'Internet';

export const AuthProvider: React.FC = ({ children }) => {
    const userInfo = useUserInfo();
    const [originNetwork, setOriginNetwork] = useState<OriginNetwork>('LAN');

    useEffect(() => {
    (async function() {
      if (userInfo) {
        setOriginNetwork(userInfo.origin_network);
      }
    })();
  }, [userInfo]);

    if (originNetwork !== 'LAN') {
        const accessDeniedUrl = 'https://shared.sgmarkets.com/unauthorized/?redirectUri=';
        window.open(accessDeniedUrl, '_self', 'noopener');
    }
  return <>{children}</>;
};
