import { useEffect, useState } from 'react';
import { BUS_TOPIC_SGCONNECT_USERINFO, SGConnectUser } from '../helpers';
export function useUserInfo(): SGConnectUser | undefined {
  const [userInfo, setUserInfo] = useState<SGConnectUser>();
  useEffect(() => {
    const id = (window as any).SGWTWidgetConfiguration.bus.subscribe(
      BUS_TOPIC_SGCONNECT_USERINFO,
      (userInfo?: SGConnectUser) => {
        if (userInfo) {
          setUserInfo(userInfo);
        }
      }
    );
    return () => {
      (window as any).SGWTWidgetConfiguration.bus.unsubscribe(id);
    };
  }, []);
  return userInfo;
}