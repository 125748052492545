export const BUS_TOPIC_GLOBALLANGUAGE = "global.language";
export const BUS_TOPIC_SGCONNECT_ACCESSTOKEN = "sg-connect.access-token";
export const BUS_TOPIC_SGCONNECT_USERCONNECTION = "sg-connect.user-connection";
export const BUS_TOPIC_SGCONNECT_USERINFO = "sg-connect.user-info";
export const BUS_TOPIC_SGCONNECT_GRANTEDSCOPE = "sg-connect.granted-scope";

type BusEventCallback<T> = (payload: T | undefined) => void;
type SubscriptionHandle = any;

export interface IUserConnection {
  claims: Object;
  connected: boolean;
  mail: string;
}

export interface SGConnectUser {
  origin_network: 'LAN' | 'WAN' | 'Internet';
}
export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined;
  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;
  unsubscribe(handle: SubscriptionHandle): void;
}

export function getWidgetBus(): SgWidgetsBus | null {
  const widgetConfiguration = window.SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus ? widgetConfiguration.bus : null;
}

export function getWidget<T extends HTMLElement>(tagName: string) {
  return document.querySelector(tagName) as T;
}

export function getAuthorizationHeader(): string | undefined {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<string>(BUS_TOPIC_SGCONNECT_ACCESSTOKEN);
  }
  return undefined;
}

export function getUserConnection(): IUserConnection | undefined {
  const bus = getWidgetBus();
  return bus ? bus.dangerouslyGetCurrentValue<IUserConnection>(BUS_TOPIC_SGCONNECT_USERCONNECTION) : undefined;
}

export function getUserEmail(): string | undefined {
  const userConnection = getUserConnection();
  return userConnection ? userConnection.mail : undefined;
}
